import { memo, useEffect } from 'react'

import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link, To, useMatch, useNavigate } from 'react-router-dom'

import { Kbd } from '@shared/components/MaterialUIEnhancements'
import { SnackPanelActions, SnackPanelActionButton } from '@shared/components/Snackbar/SnackPanelActions'
import { useHotkeys } from '@shared/utils/hooks'

type ViewEntryActionProps = {
  to: To
  onClose: () => void
}

const ViewEntryActionRaw = ({ to, onClose }: ViewEntryActionProps) => {
  const { t } = useTranslation(['domain'])

  const navigate = useNavigate()

  const pathMatched = useMatch(to.toString())

  useHotkeys([
    [
      'space',
      () => {
        navigate(to)
        onClose()
      },
    ],
  ])

  useEffect(() => {
    if (pathMatched) {
      onClose()
    }
  }, [onClose, pathMatched])

  return (
    <SnackPanelActions>
      <SnackPanelActionButton autoFocus component={Link} to={to} onClick={onClose}>
        {t('domain:Workspace.AvcTask.view')}
        <Typography sx={{ display: 'inline', ml: 1 }} variant="text-sm">
          <Kbd sx={{ fontSize: '0.8em' }}>{t('domain:Workspace.AvcTask.space')}</Kbd>
        </Typography>
      </SnackPanelActionButton>
    </SnackPanelActions>
  )
}

export const ViewEntryAction = memo(ViewEntryActionRaw)

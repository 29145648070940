import { ElementType, memo, ReactNode, useMemo } from 'react'

import {
  ListItem,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemProps,
  ListItemText,
  ListItemTextProps,
  Skeleton,
  Typography,
  TypographyProps,
} from '@mui/material'
import { Link, matchPath, useLocation, useMatch, useResolvedPath } from 'react-router-dom'

type ListItemNavLinkProps = {
  label?: ReactNode
  loading?: boolean
  startIcon?: ReactNode
  TypographyProps?: TypographyProps<'h4'>
  ListItemTextProps?: ListItemTextProps
  ListItemButtonProps?: Omit<ListItemButtonProps<typeof Link>, 'children' | 'sx' | 'to' | 'disabled'>
}

type ListItemLinkVariants =
  | {
      matchWildCard: true
      matchExactly?: never
    }
  | {
      matchWildCard?: never
      matchExactly: string[]
    }
  | {
      matchWildCard?: never
      matchExactly?: never
    }

type ListItemIconVariants =
  | {
      endIcon: ReactNode
      endAction?: never
    }
  | {
      endIcon?: never
      endAction: ReactNode
    }
  | {
      endIcon?: never
      endAction?: never
    }

const ListItemNavLinkRaw = <C extends ElementType>({
  label,
  loading,
  startIcon,
  endIcon,
  endAction,
  matchWildCard,
  matchExactly,
  TypographyProps,
  ListItemTextProps,
  to,
  sx,
  ListItemButtonProps,
  ...listItemProps
}: Omit<ListItemProps<C, { component?: C }>, 'children' | 'sx' | 'disabled'> &
  ListItemNavLinkProps &
  ListItemLinkVariants &
  ListItemIconVariants &
  Pick<ListItemButtonProps<typeof Link>, 'sx' | 'to' | 'disabled'>) => {
  const location = useLocation()
  const { pathname: resolvedToPath } = useResolvedPath(`${to}${matchWildCard ? '/*' : ''}`)
  const match = useMatch({ path: resolvedToPath })
  const exactMatches = useMemo(
    () =>
      !matchWildCard &&
      !!matchExactly &&
      matchExactly.map((match) => matchPath(`${to}/${match}`, location.pathname)).some((item) => !!item),
    [location.pathname, matchExactly, matchWildCard, to],
  )

  return (
    <ListItem
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        '& .MuiListItemText-root': {
          mr: 4,
        },
      }}
      disablePadding
      {...listItemProps}
      secondaryAction={endAction}
    >
      <ListItemButton
        {...ListItemButtonProps}
        sx={[{ display: 'flex', alignItems: 'center' }, !!endAction && { flex: 1 }, ...(Array.isArray(sx) ? sx : [sx])]}
        component={Link}
        to={to}
        selected={exactMatches || !!match}
      >
        {startIcon && (
          <ListItemIcon
            sx={{
              color: (theme) => (exactMatches || !!match ? theme.palette.info.main : 'inherit'),
            }}
          >
            {startIcon}
          </ListItemIcon>
        )}
        <ListItemText
          disableTypography
          primary={
            typeof label === 'string' ? (
              <Typography variant="text" {...TypographyProps} noWrap>
                {loading ? <Skeleton variant="text" /> : label}
              </Typography>
            ) : (
              label
            )
          }
          {...ListItemTextProps}
        />
        {endIcon && <ListItemIcon sx={{ ml: 'auto', minWidth: 'unset' }}>{endIcon}</ListItemIcon>}
      </ListItemButton>
    </ListItem>
  )
}

export const ListItemNavLink = memo(ListItemNavLinkRaw) as typeof ListItemNavLinkRaw

import { add, formatDuration as DateFNSFormatDuration, DurationUnit, Locale, sub } from 'date-fns'

export const validStartDate = sub(new Date(Date.now()), { years: 100 })
export const validEndDate = add(new Date(Date.now()), { years: 100 })

type Units = 'seconds' | 'minutes' | 'hours' | 'days' | 'years'

export const formatDuration = (
  duration: number,
  { seconds = true, minutes, hours, days, years }: Partial<Record<Units, boolean>> & { seconds: boolean },
  /**
   * @deprecated Not used anymore, use `dateFNSFormatDurationOptions` instead
   */
  options?: { omitEmptyValues?: boolean } & (
    | { suffixed?: false; longSuffix?: never; customSuffixes?: never }
    | {
        suffixed: true
        longSuffix?: boolean
        customSuffixes?: Partial<Record<Units, string>>
      }
  ),
  dateFNSFormatDurationOptions?: {
    format?: DurationUnit[]
    zero?: boolean
    delimiter?: string
    locale?: Locale
  },
) => {
  const calculatedUnits: Partial<Record<Units, number>> = {
    ...(years && { years: Math.floor(duration / 60 / 60 / 24 / 365) }),
    ...(days && { days: Math.floor(duration / 60 / 60 / 24) % 365 }),
    ...(hours && { hours: Math.floor(duration / 60 / 60) % 24 }),
    ...(minutes && { minutes: Math.floor(duration / 60) % 60 }),
    ...(seconds && { seconds: Math.floor(duration) % 60 }),
  }
  if (options) {
    const suffixes: Record<Units, string> = {
      years: options?.customSuffixes?.years || options?.longSuffix ? 'years' : 'y',
      days: options?.customSuffixes?.days || options?.longSuffix ? 'days' : 'd',
      hours: options?.customSuffixes?.hours || options?.longSuffix ? 'hours' : 'h',
      minutes: options?.customSuffixes?.minutes || options?.longSuffix ? 'minutes' : 'min',
      seconds: options?.customSuffixes?.seconds || options?.longSuffix ? 'seconds' : 'sec',
    }

    let temp = calculatedUnits
    if (options?.omitEmptyValues) {
      temp = Object.entries(calculatedUnits)
        .filter(([_, value]) => value > 0)
        .reduce(
          (acc, [unit, value]) => ({
            ...acc,
            [unit]: value,
          }),
          {},
        )
    }

    return Object.entries(temp).map(([unit, value]) =>
      options?.suffixed ? `${value} ${suffixes[unit as Units]}` : value,
    )
  }

  return DateFNSFormatDuration(calculatedUnits, dateFNSFormatDurationOptions)
}

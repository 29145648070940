import { ForwardedRef, forwardRef, Key, memo, ReactNode, useCallback, useEffect, useRef, useState } from 'react'

import { IconButton, SxProps, useTheme } from '@mui/material'
import { useAnimate, useMotionValue, useTransform, motion, AnimatePresence } from 'framer-motion'

import { mergeRefs } from '@shared/utils/common'
import { popInOut } from '@shared/utils/support'

type AnimatedCounterButtonProps = {
  action: () => void
  children: (props: {
    key: Key
    component: typeof motion.svg
    variants: typeof popInOut
    initial: string
    animate: string
    exit: string
  }) => ReactNode
  sx?: SxProps
}

// Transfer props are provided to enable Tooltip e.g.
const AnimatedCounterButtonRaw = (
  { action, children, sx, ...transferProps }: AnimatedCounterButtonProps & Record<string, unknown>,
  ref: ForwardedRef<HTMLElement>,
) => {
  const theme = useTheme()

  const [coolOff, setCoolOff] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const z = useRef(0)
  const [scope, animate] = useAnimate()
  const count = useMotionValue(3)
  const rounded = useTransform(count, Math.ceil)

  const handleRefreshClick = useCallback(async () => {
    action()

    z.current = z.current + 360

    setDisabled(true)

    await animate(scope.current, { rotateZ: z.current }, { duration: 0.8, delay: 0.1, type: 'spring' })

    setCoolOff(true)
  }, [action, animate, scope])

  useEffect(() => {
    if (coolOff) {
      animate(count, 0.1, { duration: 3, ease: 'linear' })
    }
  }, [animate, coolOff, count])

  useEffect(() => {
    count.on('animationComplete', () => {
      setCoolOff(false)

      setTimeout(() => {
        count.set(3)
        setDisabled(false)
      }, 300)
    })
  }, [count])

  return (
    <IconButton
      {...transferProps}
      sx={[
        {
          display: 'grid',
          placeItems: 'center',
          font: 'unset',
          '&.Mui-disabled': { color: 'white !important' },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      ref={mergeRefs(scope, ref)}
      onClick={handleRefreshClick}
      disabled={disabled}
      component={motion.button}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <AnimatePresence mode="wait" initial={false}>
        {coolOff ? (
          <motion.p
            style={{
              width: 24,
              height: 24,
              display: 'grid',
              placeItems: 'center',
              margin: 0,
              font: 'unset',
              ...theme.typography['heading-sm'],
            }}
            key="counter"
            variants={popInOut}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            {rounded}
          </motion.p>
        ) : (
          children({
            key: 'animated-icon-button',
            component: motion.svg,
            variants: popInOut,
            initial: 'hidden',
            animate: 'visible',
            exit: 'hidden',
          })
        )}
      </AnimatePresence>
    </IconButton>
  )
}

export const AnimatedCounterButton = memo(forwardRef(AnimatedCounterButtonRaw)) as typeof AnimatedCounterButtonRaw

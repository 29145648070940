import { memo } from 'react'

import { PlusOne, Refresh } from '@mui/icons-material'
import { Box, Button, styled, SxProps, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { AnimatedCounterButton } from '@shared/components/MaterialUIEnhancements/IconButton'

export const ListOperationBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  columnGap: theme.spacing(2),
  marginLeft: 'auto',
}))

type BasicListOperationProps = {
  refreshAction: () => void
  createAction: () => void
  sx?: SxProps
}

const BasicListOperationRaw = ({ refreshAction, createAction, sx }: BasicListOperationProps) => {
  const { t } = useTranslation()

  return (
    <ListOperationBox sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Tooltip title={t('refresh')} placement="left">
        <AnimatedCounterButton action={refreshAction}>{(props) => <Refresh {...props} />}</AnimatedCounterButton>
      </Tooltip>
      <Button variant="contained" endIcon={<PlusOne />} onClick={createAction}>
        {t('create')}
      </Button>
    </ListOperationBox>
  )
}

export const BasicListOperation = memo(BasicListOperationRaw)

import {
  TenantsTenantExternalAzureAdNativewavescloudLoginPostRequest,
  UsersUserIdPersonalAccessTokensGetRequest,
  UsersUserIdPersonalAccessTokensTokenIdGetRequest,
} from '@nativewaves/platform-sdk-browser/identity'
import { infiniteQueryOptions, queryOptions } from '@tanstack/react-query'
import { omit } from 'lodash-es'

import { IdentityAPI } from 'services/api'

const baseIdentifier = 'identity'

export const externalQueryKeys = {
  base: () => [baseIdentifier, 'external'] as const,
  nativewavesCloudLoginWithAzureAD: (params: TenantsTenantExternalAzureAdNativewavescloudLoginPostRequest) =>
    queryOptions({
      queryKey: [...externalQueryKeys.base(), 'nwcloud-login-ad', { ...omit(params, 'aDB2CLoginRequest') }],
      queryFn: () => IdentityAPI.external.tenantsTenantExternalAzureAdNativewavescloudLoginPost(params),
    }),
}

export const userQueryKeys = {
  base: () => [baseIdentifier, 'user'] as const,
  personalAccessTokenLists: () => [...userQueryKeys.base(), 'personal-access-token', 'list'] as const,
  personalAccessTokenList: (params: UsersUserIdPersonalAccessTokensGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...userQueryKeys.personalAccessTokenLists(), params] as const,
      queryFn: ({ pageParam, signal }) =>
        IdentityAPI.users.usersUserIdPersonalAccessTokensGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  personalAccessTokenDetails: () => [...userQueryKeys.base(), 'personal-access-token', 'detail'] as const,
  personalAccessTokenDetail: (params: UsersUserIdPersonalAccessTokensTokenIdGetRequest) =>
    queryOptions({
      queryKey: [...userQueryKeys.personalAccessTokenDetails(), params] as const,
      queryFn: () => IdentityAPI.users.usersUserIdPersonalAccessTokensTokenIdGet(params),
    }),
}
